html body {
  font-family: 'Red Hat Display', sans-serif;
}

/* NAVBAR */
.navbar-nav a {
  margin-right: 90px;
  color: rgba(0,0,0,.5);
  transition-duration: 0.25s; /* delays for 1 second */
  -webkit-transition-duration: 0.25s; /*for Safari & Chrome*/
}

.navbar-nav a:hover {
  color: rgb(230 69 54 / 60%);
  text-decoration: none;
  transition-duration: 0.25s; /* delays for 1 second */
  -webkit-transition-duration: 0.25s; /*for Safari & Chrome*/
}

/* ACCUEIL */
.accueil {
  background: url('./images/pc-img1-blanc.jpg');
  height: 890px;
  background-repeat: no-repeat;
  background-size: cover;
}

.accueil img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.accueil .titre {
  position: absolute;
  margin-top: 250px;
}

.accueil #titre1, .accueil #titre2, .accueil #titre3{
  font-weight: bold;
  opacity: 0.79;
}

.accueil #titre1{
  font-size : 10em;
}

.accueil #titre2{
  font-size : 6em;
  color: #e64536;
}


/* A PROPOS */
.a-propos {
  position: relative;
}

.a-propos::before{
  content: "";
  background-image: url('./images/A-pcaid.svg');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size:40%;
  background-position-x: 1270px;
  background-position-y: 150px;
  opacity: 0.6;
  position: absolute;
}

.content-apropos {
  padding-top: 170px;
  color: #6c757d;
}

.content-apropos p {
  padding-top: 90px;
  font-size: 1.5em;  
}

.content-apropos span {
  font-size: 1.8em;  
}

.content-apropos .col {
  width: 100%;
  height: 100%;
}

.content-apropos img {
  width: 100%;
  border-radius : 50px;
}

/* CONTACT */
.contact {
  background-color: #f7f7f7;
  background-image:
    linear-gradient(
      to bottom, 
      white, #f7f7f7
    );
  color: #6c757d;
}
.content-contact {
  padding-bottom: 120px;
  padding-top: 140px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.content-contact .titre {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #e64536;
}

.contact .form-contact {
  margin-top: 50px;
}

.content-contact input {
  border-radius : 20px;
}

.content-contact #sujetContact {
  border-radius : 20px;
}

.content-contact .bouton-envoyer {
  background-color : #e64536;
  border-color : transparent;
  border-radius : 20px;
  width: 130px;
}

.content-contact .btn-primary.disabled {
  background-color : #e64536;
}

.content-contact .bouton-envoyer:hover {
  background-color : #da392a;
}

/* FOOTER */
.footer {
  color: #6c757d;
  height: 270px;
  background-color: #f7f7f7;
  background-image:
    linear-gradient(
      to bottom, 
      #f7f7f7, #d6d4d4
    );
  padding: 40px 40px 40px 40px;
}

.footer .pc-aid img{
  opacity: 0.6;
  height: 60px;
  margin-top: 50px;
}

/* MEDIA */
@media only screen and (max-width: 1600px) {
  /* A PROPOS */
  .a-propos::before{
    background-size:40%;
    background-position-x: 1200px;
    background-position-y: 150px;
  }
}
 
@media only screen and (max-width: 1400px) {
  /* ACCUEIL */
  .accueil {
    height: 700px;

  }
  .accueil .titre {
    margin-top: 150px;
  }

  .accueil #titre1{
    font-size : 10em;
  }
  
  .accueil #titre2{
    font-size : 5em;
  }

  /* A PROPOS */
  .a-propos::before{
    background-size:40%;
    background-position-x: 1000px;
    background-position-y: 150px;
  }

}

@media only screen and (max-width: 1200px) {
  /* ACCUEIL */
  .accueil .titre {
    margin-top: 200px;
  }

  .accueil #titre1{
    font-size : 7em;
  }

  .accueil #titre2{
    font-size : 4em;
  }

  /* A PROPOS */
  .a-propos::before{
    background-size:50%;
    background-position-x: 700px;
    background-position-y: 150px;
  }
  .content-apropos p {
    padding-top: 50px;
    font-size: 1.55em;  
  }

}

@media only screen and (max-width: 900px) {
  /* ACCUEIL */
  .accueil #titre1{
    font-size : 5em;
  }

  .accueil #titre2{
    font-size : 3em;
  }

  /* A PROPOS */
  .a-propos::before{
    background-size:70%;
    background-position-x: 400px;
    background-position-y: 150px;
  }

}

@media only screen and (max-width: 650px) {
  /* ACCUEIL */
  .accueil #titre1{
    font-size : 4.5em;
  }

  /* A PROPOS */
  .a-propos::before{
    background-size:90%;
    background-position-x: 200px;
    background-position-y: 150px;
  }
  .content-apropos .row {
    display: block;
    text-align: center;
  }
  .content-apropos .row {
    display: block;
  }
  .content-apropos img {
    margin: auto;
    position: relative;
    width: 200px;
  }
  .content-apropos #img1-a-propos {
    float: left;
  }
  .content-apropos #img2-a-propos {
    float: right;
  }

  /* CONTACT */
  .content-contact .row {
    display: block;
  }

  /* FOOTER */
  .footer {
    height: 100%;
    text-align: center;
  }
  .footer .row{
    display: block;
  }
  .footer .col{
    padding-bottom: 40px;
  }
  .footer .pc-aid img {
    margin-top: auto;
    height: 40px;
  }
  
}  

@media only screen and (max-width: 500px) {
  /* ACCUEIL */
  .accueil {
    height: 650px;
  }
  
  .accueil #titre1{
    font-size : 3.7em;
  }

  .accueil #titre2{
    font-size : 2em;
  }

  /* A PROPOS */
  .a-propos::before{
    background-size:100%;
    background-position-x: 150px;
    background-position-y: 150px;
  }
}

@media only screen and (max-width: 450px) {
  .content-apropos #img1-a-propos, .content-apropos #img2-a-propos {
    float: none;
    margin-top: 30px;
    display: block;
  }
}

@media only screen and (max-width: 430px) {
  /* ACCUEIL */
  .accueil #titre1{
    font-size : 2.7em;
  }
  .accueil #titre2{
    font-size : 2em;
  }

}